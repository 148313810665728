import React, { useState } from "react"
import PageLayout from "../components/new-pagelayout"
//import { StaticImage } from "gatsby-plugin-image"
import axios from "axios"

const ContactPage = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: undefined,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    const formData = new FormData(form)
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/622d6a76-7d93-4198-94c7-3eb4750915d4",
      data: formData,
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <PageLayout pageTitle={"Kontakt"}>
      <section class="py-20">
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap mx-4">
            <div class="w-full lg:w-1/2 mb-12 lg:mb-0">
              <div class="max-w-md">
                <h2 class="mb-12 text-4xl font-semibold font-heading">
                  Kontakt&shy;information
                </h2>
                <p class="text-gray-500 mb-6">
                  Vi bygger kontinuerligt ut vårt nätverk av leverantörer,
                  samarbetspartners, återförsäljare och kunder. Vi kan även
                  handleda examensarbeten och uppsatser.
                </p>
                <h3 class="mb-4 text-2xl font-semibold">Adress</h3>
                <p class="text-gray-500">Drone Bone AB</p>
                <p class="text-gray-500">Matildebergsgatan 40</p>
                <p class="mb-10 text-gray-500">431 38 Mölndal</p>
                <h3 class="mb-4 text-2xl font-semibold">Kontakta oss</h3>
                <a class="text-gray-500" href="mailto:kontakt@dronebone.se">
                  kontakt@dronebone.se
                </a>
              </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
              <form
                //action="https://getform.io/f/622d6a76-7d93-4198-94c7-3eb4750915d4"
                //method="POST"
                onSubmit={handleOnSubmit}
              >
                <div class="relative flex flex-wrap mb-6">
                  <input
                    class="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded"
                    type="text"
                    name="name"
                    placeholder="namn"
                  ></input>
                  <span class="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs z-50">
                    Ditt namn
                  </span>
                </div>
                <div class="relative flex flex-wrap mb-6">
                  <input
                    class="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded"
                    type="email"
                    name="email"
                    placeholder="email"
                  ></input>
                  <span class="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">
                    Din emailadress
                  </span>
                </div>
                <div class="relative flex flex-wrap mb-6">
                  <textarea
                    class="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded resize-none"
                    id="1"
                    name="message"
                    cols="30"
                    rows="10"
                    placeholder="Skriv här..."
                  ></textarea>
                  <span class="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">
                    Ditt meddelande
                  </span>
                </div>
                <button
                  class="w-full inline-block px-6 py-4 text-sm text-white bg-red-400 hover:bg-red-300 rounded transition duration-200"
                  type="submit"
                  disabled={serverState.submitting}
                >
                  Skicka
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/*
      <StaticImage
        alt="Drönare som flyger ut över havet"
        src="../images/the-end.jpeg"
      />
      */}
    </PageLayout>
  )
}

export default ContactPage
